@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'HelveticaNeueLT';
        src: url('./Assets/Fonts/HelveticaNeueLTArabic-Roman.woff2') format('woff2'),
            url('./Assets/Fonts/HelveticaNeueLTArabic-Roman.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'HelveticaNeueLT';
        src: url('./Assets/Fonts/HelveticaNeueLTArabic-Bold.woff2') format('woff2'),
            url('./Assets/Fonts/HelveticaNeueLTArabic-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Alata';
        src: url('./Assets/Fonts/Alata-Regular.woff2') format('woff2'),
            url('./Assets/Fonts/Alata-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
}


